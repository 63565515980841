import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { TApiError, HttpClient } from '../../_http';
import { TRequestPasswordResetForm } from '../_models';

function requestPasswordReset(body: TRequestPasswordResetForm): Promise<void> {
  return HttpClient.post('auth/reset-password-request', body);
}

export function useRequestPasswordReset() {
  const history = useHistory();

  return useMutation<void, TApiError, TRequestPasswordResetForm>('requestPasswordReset', requestPasswordReset, {
    onSuccess: () => history.push('auth/login'),
  });
}
