import { useQuery } from 'react-query';
import { HttpClient } from '../../_http';
import { AvailabilityDto } from '../_models/AvailabilityDto';
import { TAvailabilityForm } from '../_models/AvailabilityForm';
import { transformIncomingAvailabilities } from '../_utils/AvailabilitiesHelper';

function getAvailabilities(userId: string): Promise<AvailabilityDto[]> {
  return HttpClient.get(`availabilities/${userId}`);
}

export function useGetAvailabilities(userId: string) {
  const { data, isLoading } = useQuery('getAvailabilities', () => getAvailabilities(userId), {
    select: transformIncomingAvailabilities,
  });
  const availabilityForm: TAvailabilityForm = data ? { availabilities: data } : null;
  return { data: availabilityForm, isLoading };
}
