import { FC, useEffect, useState } from 'react';
import { useAuthenticationContext } from '../_context';
import { Button } from '../_shared';
import { formatDate } from '../_utils/dateHelpers';
import { useBookMeeting } from './_queries/useBookMeeting';

type TProps = {
  date: Date;
  hostId: string;
};

const MeetingConfirm: FC<TProps> = ({ date, hostId }: TProps) => {
  const { profile } = useAuthenticationContext();
  const [meetingStartTime, setMeetingStartTime] = useState<Date>();
  const [meetingEndTime, setMeetingEndTime] = useState<Date>();
  const { mutate: bookMeeting } = useBookMeeting();

  useEffect(() => {
    setMeetingStartTime(date);
    // add 30 minutes
    setMeetingEndTime(new Date(new Date(date).getTime() + 30 * 60 * 1000));
  }, [date]);

  return (
    <>
      {!!meetingStartTime && (
        <div className="meeting-confirm">
          <div className="meeting-box">
            <div className="meeting-wrapper">
              <div className="meeting-header">Meeting:</div>
              <div className="meeting-date">
                {formatDate(new Date(meetingStartTime), 'EEEE d MMM yyyy') +
                  ' at ' +
                  formatDate(new Date(meetingStartTime), 'HH:mm')}
              </div>
            </div>

            <div className="confirm-button-wrapper">
              <Button
                className="confirm-button"
                onClick={() =>
                  bookMeeting({
                    endTime: meetingEndTime,
                    guest: profile.id,
                    host: hostId,
                    startTime: meetingStartTime,
                  })
                }
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MeetingConfirm;
