import { Link } from 'react-router-dom';
import { I18n } from '../../_translations';
import { InputField, Button, ErrorMessage } from '../../_shared';
import { useForm } from '../../_hooks';
import { TFormValidationErrors } from '../../_hooks/useForm';
import { TApiError, HttpStatus } from '../../_http';
import { formValidator } from '../../_utils/formValidation';
import { TLoginForm } from '../_models';
import { useLogin } from '../_queries';
import './login.scss';

const initialForm: TLoginForm = {
  password: '',
  username: '',
};

function validateForm(values: TLoginForm): TFormValidationErrors<TLoginForm> {
  return {
    password: formValidator.required(values.password),
    username: formValidator.email(values.username),
  };
}

function errorAsString(error?: TApiError): string {
  if (error?.statusCode === HttpStatus.Unauthorized) return I18n.labels.AUTH.ERRORS.UNAUTHORIZED;
  if (error?.error === 'USER_STATE_NOT_ALLOWED') return I18n.labels.AUTH.ERRORS.USER_STATE_NOT_ALLOWED;
  return null;
}

const Login = () => {
  const { isLoading, error, mutate: login } = useLogin();
  const form = useForm<TLoginForm>({
    error,
    initialForm,
    submitForm: values => login(values),
    validateForm,
  });
  const errorMessage = errorAsString(error);

  return (
    <main className="right-container login">
      <h1>{I18n.labels.AUTH.LOGIN.TITLE}</h1>
      <form onSubmit={form.submit}>
        <ErrorMessage isGlobal isVisible={!!errorMessage}>
          {errorMessage}
        </ErrorMessage>
        <InputField
          autoComplete="username"
          label={I18n.labels.AUTH.LOGIN.USERNAME}
          name="username"
          onChange={form.setAttribute}
          type="email"
          validation={form.validationErrors.username}
          value={form.values.username}
        />
        <InputField
          autoComplete="current-password"
          label={I18n.labels.AUTH.LOGIN.PASSWORD}
          name="password"
          onChange={form.setAttribute}
          type="password"
          validation={form.validationErrors.password}
          value={form.values.password}
        />
        <div className="actions">
          <Link to="/auth/request-password-reset">{I18n.labels.AUTH.LOGIN.FORGOT_PASSWORD}</Link>
          <Button loading={isLoading} theme="primary" type="submit">
            {I18n.labels.AUTH.LOGIN.LOGIN}
          </Button>
        </div>
      </form>
    </main>
  );
};

export default Login;
