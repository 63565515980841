export const translationKeys = {
  AUTH: {
    SIDEBAR_TITLE: '[[AUTH.SIDEBAR_TITLE]]',
    LOGIN: {
      FORGOT_PASSWORD: '[[AUTH.LOGIN.FORGOT_PASSWORD]]',
      LOGIN: '[[AUTH.LOGIN.LOGIN]]',
      PASSWORD: '[[AUTH.LOGIN.PASSWORD]]',
      TITLE: '[[AUTH.LOGIN.TITLE]]',
      USERNAME: '[[AUTH.LOGIN.USERNAME]]',
    },
    LOGOUT: '[[AUTH.LOGOUT]]',
    PASSWORD_GUIDELINES: '[[AUTH.PASSWORD_GUIDELINES]]',
    REGISTER: { REGISTER: '[[AUTH.REGISTER.REGISTER]]', TITLE: '[[AUTH.REGISTER.TITLE]]' },
    RESET_PASSWORD: { RESET: '[[AUTH.RESET_PASSWORD.RESET]]', TITLE: '[[AUTH.RESET_PASSWORD.TITLE]]' },
    REQUEST_PASSWORD_RESET: {
      DESCRIPTION: '[[AUTH.REQUEST_PASSWORD_RESET.DESCRIPTION]]',
      RESET: '[[AUTH.REQUEST_PASSWORD_RESET.RESET]]',
      TITLE: '[[AUTH.REQUEST_PASSWORD_RESET.TITLE]]',
    },
    CHANGE_PASSWORD: {
      TITLE: '[[AUTH.CHANGE_PASSWORD.TITLE]]',
      OLD_PASSWORD: '[[AUTH.CHANGE_PASSWORD.OLD_PASSWORD]]',
      NEW_PASSWORD: '[[AUTH.CHANGE_PASSWORD.NEW_PASSWORD]]',
      REPEAT_NEW_PASSWORD: '[[AUTH.CHANGE_PASSWORD.REPEAT_NEW_PASSWORD]]',
    },
    ERRORS: {
      UNAUTHORIZED: '[[AUTH.ERRORS.UNAUTHORIZED]]',
      USER_STATE_NOT_ALLOWED: '[[AUTH.ERRORS.USER_STATE_NOT_ALLOWED]]',
      RESET_TOKEN_INVALID: '[[AUTH.ERRORS.RESET_TOKEN_INVALID]]',
      RESET_TOKEN_EXPIRED: '[[AUTH.ERRORS.RESET_TOKEN_EXPIRED]]',
      INVALID_OLD_PASSWORD: '[[AUTH.ERRORS.INVALID_OLD_PASSWORD]]',
    },
    TOASTER: {
      CHOOSE_PASSWORD: '[[AUTH.TOASTER.CHOOSE_PASSWORD]]',
      REQUEST_PASSWORD_RESET: '[[AUTH.TOASTER.REQUEST_PASSWORD_RESET]]',
      CHANGE_PASSWORD: '[[AUTH.TOASTER.CHANGE_PASSWORD]]',
    },
  },
  PROFILE: {
    TITLE: '[[PROFILE.TITLE]]',
    CHANGE_PASSWORD: { TITLE: '[[PROFILE.CHANGE_PASSWORD.TITLE]]', DESCRIPTION: '[[PROFILE.CHANGE_PASSWORD.DESCRIPTION]]' },
    DEV_MODE: {
      TITLE: '[[PROFILE.DEV_MODE.TITLE]]',
      DESCRIPTION: '[[PROFILE.DEV_MODE.DESCRIPTION]]',
      BUTTON: { TURN_ON: '[[PROFILE.DEV_MODE.BUTTON.TURN_ON]]', TURN_OFF: '[[PROFILE.DEV_MODE.BUTTON.TURN_OFF]]' },
    },
    LOCALE: {
      TITLE: '[[PROFILE.LOCALE.TITLE]]',
      DESCRIPTION: '[[PROFILE.LOCALE.DESCRIPTION]]',
      BUTTON: '[[PROFILE.LOCALE.BUTTON]]',
    },
  },
  ROLES: {
    NAME: '[[ROLES.NAME]]',
    PERMISSIONS: {
      TITLE: '[[ROLES.PERMISSIONS.TITLE]]',
      FEATURES: { ROLES: '[[ROLES.PERMISSIONS.FEATURES.ROLES]]', USERS: '[[ROLES.PERMISSIONS.FEATURES.USERS]]' },
      RIGHTS: { EDIT: '[[ROLES.PERMISSIONS.RIGHTS.EDIT]]', VIEW: '[[ROLES.PERMISSIONS.RIGHTS.VIEW]]' },
    },
    OVERVIEW: {
      CREATE_ROLE: '[[ROLES.OVERVIEW.CREATE_ROLE]]',
      CREATED_AT: '[[ROLES.OVERVIEW.CREATED_AT]]',
      EMPTY: '[[ROLES.OVERVIEW.EMPTY]]',
      TITLE: '[[ROLES.OVERVIEW.TITLE]]',
      UPDATED_AT: '[[ROLES.OVERVIEW.UPDATED_AT]]',
    },
    CREATE: { TITLE: '[[ROLES.CREATE.TITLE]]' },
    DETAIL: {
      BACK: '[[ROLES.DETAIL.BACK]]',
      BUTTON_DELETE: '[[ROLES.DETAIL.BUTTON_DELETE]]',
      LOADING: '[[ROLES.DETAIL.LOADING]]',
    },
    DELETE: { CONTENT: '[[ROLES.DELETE.CONTENT]]', CONFIRM: '[[ROLES.DELETE.CONFIRM]]' },
    ERRORS: {
      ROLE_IN_USE: '[[ROLES.ERRORS.ROLE_IN_USE]]',
      ROLE_NAME_ALREADY_IN_USE: '[[ROLES.ERRORS.ROLE_NAME_ALREADY_IN_USE]]',
    },
    TOASTER: {
      ROLE_CREATED: '[[ROLES.TOASTER.ROLE_CREATED]]',
      ROLE_UPDATED: '[[ROLES.TOASTER.ROLE_UPDATED]]',
      ROLE_DELETED: '[[ROLES.TOASTER.ROLE_DELETED]]',
    },
  },
  SHARED: {
    PROFILE: { TITLE: '[[SHARED.PROFILE.TITLE]]' },
    BUTTONS: {
      CANCEL: '[[SHARED.BUTTONS.CANCEL]]',
      CREATE: '[[SHARED.BUTTONS.CREATE]]',
      DELETE: '[[SHARED.BUTTONS.DELETE]]',
      SAVE: '[[SHARED.BUTTONS.SAVE]]',
    },
    DETAIL: {
      CREATED_AT: '[[SHARED.DETAIL.CREATED_AT]]',
      CREATED_AT_BY: '[[SHARED.DETAIL.CREATED_AT_BY]]',
      UPDATED_AT: '[[SHARED.DETAIL.UPDATED_AT]]',
      UPDATED_AT_BY: '[[SHARED.DETAIL.UPDATED_AT_BY]]',
    },
    DROPDOWN: { OPTION_CLEAR_VALUE: '[[SHARED.DROPDOWN.OPTION_CLEAR_VALUE]]', NO_OPTIONS: '[[SHARED.DROPDOWN.NO_OPTIONS]]' },
    FILE_UPLOAD: { TITLE: '[[SHARED.FILE_UPLOAD.TITLE]]', REQUIREMENTS: '[[SHARED.FILE_UPLOAD.REQUIREMENTS]]' },
    NAVIGATION: { ROLES: '[[SHARED.NAVIGATION.ROLES]]', USERS: '[[SHARED.NAVIGATION.USERS]]' },
    LOCALE: { EN: '[[SHARED.LOCALE.EN]]', NL: '[[SHARED.LOCALE.NL]]' },
    PLACEHOLDER: {
      DATE: '[[SHARED.PLACEHOLDER.DATE]]',
      SEARCH: '[[SHARED.PLACEHOLDER.SEARCH]]',
      TIME: '[[SHARED.PLACEHOLDER.TIME]]',
    },
    LOADING_APPLICATION: '[[SHARED.LOADING_APPLICATION]]',
  },
  USERS: {
    EMAIL: '[[USERS.EMAIL]]',
    FIRST_NAME: '[[USERS.FIRST_NAME]]',
    LAST_NAME: '[[USERS.LAST_NAME]]',
    ROLE: '[[USERS.ROLE]]',
    DETAIL: {
      BACK: '[[USERS.DETAIL.BACK]]',
      BUTTON_DELETE: '[[USERS.DETAIL.BUTTON_DELETE]]',
      DETAILS: { TITLE: '[[USERS.DETAIL.DETAILS.TITLE]]' },
      STATUS: {
        TITLE: '[[USERS.DETAIL.STATUS.TITLE]]',
        SUBTITLE: '[[USERS.DETAIL.STATUS.SUBTITLE]]',
        DESCRIPTION: {
          ACTIVE: '[[USERS.DETAIL.STATUS.DESCRIPTION.ACTIVE]]',
          INACTIVE: '[[USERS.DETAIL.STATUS.DESCRIPTION.INACTIVE]]',
          REGISTERING: '[[USERS.DETAIL.STATUS.DESCRIPTION.REGISTERING]]',
        },
        BUTTON: {
          ACTIVATE: '[[USERS.DETAIL.STATUS.BUTTON.ACTIVATE]]',
          DEACTIVATE: '[[USERS.DETAIL.STATUS.BUTTON.DEACTIVATE]]',
          RESEND_REGISTER_MAIL: '[[USERS.DETAIL.STATUS.BUTTON.RESEND_REGISTER_MAIL]]',
        },
      },
      LOADING: '[[USERS.DETAIL.LOADING]]',
    },
    OVERVIEW: {
      CREATE_USER: '[[USERS.OVERVIEW.CREATE_USER]]',
      EMPTY: '[[USERS.OVERVIEW.EMPTY]]',
      TITLE: '[[USERS.OVERVIEW.TITLE]]',
      CREATED_AT: '[[USERS.OVERVIEW.CREATED_AT]]',
      CREATED_BY: '[[USERS.OVERVIEW.CREATED_BY]]',
      UPDATED_AT: '[[USERS.OVERVIEW.UPDATED_AT]]',
      UPDATED_BY: '[[USERS.OVERVIEW.UPDATED_BY]]',
    },
    CREATE: { TITLE: '[[USERS.CREATE.TITLE]]' },
    DEACTIVATE: { CONTENT: '[[USERS.DEACTIVATE.CONTENT]]', CONFIRM: '[[USERS.DEACTIVATE.CONFIRM]]' },
    DELETE: { CONTENT: '[[USERS.DELETE.CONTENT]]', CONFIRM: '[[USERS.DELETE.CONFIRM]]' },
    STATE: {
      TITLE: '[[USERS.STATE.TITLE]]',
      OPTIONS: {
        ACTIVE: '[[USERS.STATE.OPTIONS.ACTIVE]]',
        INACTIVE: '[[USERS.STATE.OPTIONS.INACTIVE]]',
        REGISTERING: '[[USERS.STATE.OPTIONS.REGISTERING]]',
      },
    },
    ERRORS: { EMAIL_ALREADY_IN_USE: '[[USERS.ERRORS.EMAIL_ALREADY_IN_USE]]' },
    TOASTER: {
      USER_CREATED: '[[USERS.TOASTER.USER_CREATED]]',
      USER_UPDATED: '[[USERS.TOASTER.USER_UPDATED]]',
      USER_DELETED: '[[USERS.TOASTER.USER_DELETED]]',
    },
  },
  ERRORS: {
    GENERAL: '[[ERRORS.GENERAL]]',
    PERMISSION_DENIED: '[[ERRORS.PERMISSION_DENIED]]',
    VALIDATION: {
      AFTER_DATE: '[[ERRORS.VALIDATION.AFTER_DATE]]',
      BEFORE_DATE: '[[ERRORS.VALIDATION.BEFORE_DATE]]',
      EMPTY_ARRAY: '[[ERRORS.VALIDATION.EMPTY_ARRAY]]',
      FORM: '[[ERRORS.VALIDATION.FORM]]',
      INVALID_NEW_AND_REPEATED_PASSWORD: '[[ERRORS.VALIDATION.INVALID_NEW_AND_REPEATED_PASSWORD]]',
      INVALID: '[[ERRORS.VALIDATION.INVALID]]',
      MAX_LENGTH: '[[ERRORS.VALIDATION.MAX_LENGTH]]',
      MIN_LENGTH: '[[ERRORS.VALIDATION.MIN_LENGTH]]',
      NOT_A_NUMBER: '[[ERRORS.VALIDATION.NOT_A_NUMBER]]',
      PASSWORD_TOO_SHORT: '[[ERRORS.VALIDATION.PASSWORD_TOO_SHORT]]',
      PASSWORD_UNSAFE: '[[ERRORS.VALIDATION.PASSWORD_UNSAFE]]',
      REQUIRED: '[[ERRORS.VALIDATION.REQUIRED]]',
    },
  },
};
