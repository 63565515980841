import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../../../_assets/svg/logo.svg';
import { Icon } from '../../../../_shared';
import { useAuthenticationContext } from '../../../../_context';
import './authorizedLayoutMenu.scss';

const AuthorizedLayoutMenu: FC = () => {
  const { profile } = useAuthenticationContext();

  return (
    <header className="main-menu">
      <img alt="icapps logo" src={Logo} />
      <nav>
        <NavLink to="/profile">
          <Icon name="SvgUser" size={2} />
          <span>{profile.firstName || profile.email}</span>
        </NavLink>
      </nav>
    </header>
  );
};

export default AuthorizedLayoutMenu;
