import { useQuery, UseQueryOptions } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { TProfile } from '../../profile/_models';
import { HttpClient, TApiError } from '../../_http';

function verify(token: string) {
  return HttpClient.post('auth/verify', { token });
}

export function useVerify(options: UseQueryOptions<TProfile, TApiError>) {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');

  return useQuery('verify', () => verify(token), {
    ...options,
    enabled: !!token?.length,
    onSettled: () => {
      history.push(pathname);
    },
    retry: false,
  });
}
