import { useQuery } from 'react-query';
import { HttpClient, TApiError } from '../../_http';
import { formatDate } from '../../_utils/dateHelpers';
import { AvailableTimesResponse } from '../_models/AvailableTimesResponse';

function getAvailableTimeslots(userId: string, day: string): Promise<AvailableTimesResponse> {
  return HttpClient.get<AvailableTimesResponse>('availabilities/availableTimeslots', { day, userId });
}

export function useGetAvailableTimes(userId: string, day: Date) {
  return useQuery<AvailableTimesResponse, TApiError>('getAvailableTimeslots', () =>
    getAvailableTimeslots(userId, formatDate(new Date(day), 'yyyy-MM-dd')),
  );
}
