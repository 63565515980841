import { Redirect, Route, Switch } from 'react-router-dom';
import SetAvailabilities from '../../../availabilities/SetAvailabilities';
import Profile from '../../../profile/Profile';
import Calendar from '../../../meetings/Calendar';
import MeetingBooked from '../../../meetings/MeetingBooked';
import AuthorizedLayoutMenu from './menu/AuthorizedLayoutMenu';
import './authorizedLayout.scss';

const AuthorizedLayout: React.FC = () => (
  <div className="authorized-layout">
    <AuthorizedLayoutMenu />
    <Switch>
      <Route component={Profile} path="/profile" />
      <Route component={Calendar} path="/bookmeeting/:hostId" />
      <Route component={MeetingBooked} path="/meetingbooked" />
      <Route component={SetAvailabilities} path="/set-availabilities" />
      <Redirect to="/set-availabilities" />
    </Switch>
  </div>
);

export default AuthorizedLayout;
