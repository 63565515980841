import { FC } from 'react';
import { useAuthenticationContext } from '../_context';
import AvailabilityForm from './AvailabilityForm';
import { TAvailabilityForm } from './_models/AvailabilityForm';
import { useGetAvailabilities, useCreateAvailabilities, useUpdateAvailabilities } from './_queries';
import { emptyAvailabilities } from './_utils/EmptyAvailabilities';
import './setAvailabilities.scss';

const initialForm: TAvailabilityForm = {
  availabilities: emptyAvailabilities,
};

const SetAvailabilities: FC = () => {
  const { profile } = useAuthenticationContext();
  const { data, isLoading } = useGetAvailabilities(profile.id);
  const { mutate: createAvailabilities } = useCreateAvailabilities();
  const { mutate: updateAvailabilities } = useUpdateAvailabilities();
  const interval = 30;
  // later profile.interval

  return (
    <div className="set-availabilities">
      <h1>My availabilities</h1>
      <p>
        Here you can set your availabilities by choosing a start- and end-time. People will then be able to contact you by picking
        a timeslot (timeslots are divided into 30 minutes intervals)
        {/* Later possibly profile.interval */}
      </p>
      <br />
      <h3>Set your weekly hours</h3>
      {!isLoading && (
        <AvailabilityForm
          initialForm={data || initialForm}
          isLoading={isLoading}
          step={interval * 60}
          submitForm={(values: TAvailabilityForm) => {
            if (data) {
              updateAvailabilities({ body: values });
            } else {
              createAvailabilities({ body: values });
            }
          }}
        />
      )}
    </div>
  );
};

export default SetAvailabilities;
