import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthenticationContext } from '../_context';
import { Button } from '../_shared';
import { useDeleteAccount } from './_queries';
import './profile.scss';

const Profile: FC = () => {
  const { profile } = useAuthenticationContext();
  const { mutate: deleteAccount } = useDeleteAccount();
  const history = useHistory();

  return (
    <main className="profile">
      <p>Deleting your my account will remove all your data from this platform.</p>
      <Button onClick={() => history.goBack()}>Go back</Button>
      <Button onClick={() => deleteAccount(profile.id)} theme="negative">
        Delete account
      </Button>
    </main>
  );
};

export default Profile;
