import { ReactElement } from 'react';
import { renderToString } from 'react-dom/server';
import parseStringToHtml from 'html-react-parser';
import { translationsEn, TTranslationsEn } from './typings/en';
import { translationsNl, TTranslationsNl } from './typings/nl';
import { translationKeys } from './typings/keys';

export type TLocale = 'en' | 'nl';
const defaultLocale: TLocale = 'en';

type TTranslations = TTranslationsEn | TTranslationsNl;

const translations: Record<TLocale, TTranslations> = {
  en: translationsEn,
  nl: translationsNl,
};

export class I18n {
  /**
   * Navigate through this object to find your label with autocompletion.
   */
  static get labels(): TTranslations {
    if (this.isDevMode()) return translationKeys as TTranslations;
    const locale = I18n.getLocale().split('-').length > 1 ? defaultLocale : I18n.getLocale();
    return translations[locale] || translations[defaultLocale];
  }

  /**
   * This function inserts variables within the given value. Placeholders in the value are between curly brackets.
   * E.g. For the value "This is a {{placeholder}}", you can pass { "placeholder": "piece of art"} as inserts.
   * @param value string containing placeholders
   * @param inserts object that maps a placeholder to a value. Can be a string, number, or JSX.
   * @returns a string where all given placeholders are replaces by their corresponding value.
   */
  static insert(value: string, inserts: Record<string, string | number | ReactElement> = {}): string {
    function getValueToInsert(val: string | number | ReactElement): string {
      if (typeof val === 'string') return val;
      if (typeof val === 'number') return `${val}`;
      return renderToString(val);
    }

    return Object.keys(inserts).reduce(
      (acc, key) => acc.replace(new RegExp(`{{${key}}}`, 'g'), getValueToInsert(inserts[key])),
      value,
    );
  }

  /**
   * Use this function when the value could contain HTML.
   * @param value string containing HTML tags
   * @returns JSX that correctly renders the HTML tags in the given value.
   */
  static renderStringAsHtml(value: string): ReturnType<typeof parseStringToHtml> {
    return parseStringToHtml(value);
  }

  static setLocale(locale: TLocale): void {
    window.localStorage.setItem('locale', locale);
    // Reload the window to make app re-render
    window.location.reload();
  }

  static setDevMode(flag: boolean): void {
    window.sessionStorage.setItem('dev-mode', `${flag}`);
    // Reload the window to make app re-render
    window.location.reload();
  }

  static getLocale(): TLocale {
    return (window.localStorage.getItem('locale') as TLocale) || defaultLocale;
  }

  static isDevMode(): boolean {
    return window.sessionStorage.getItem('dev-mode') === `${true}`;
  }
}
