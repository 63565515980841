import { FC } from 'react';
import { I18n } from '../../_translations';
import { formatISOString } from '../../_utils/dateHelpers';
import './timestamps.scss';

type TProps = {
  entity: {
    createdAt?: string;
    createdBy?: string;
    updatedAt?: string;
    updatedBy?: string;
  };
};

function formatTimestamp(at: string, by: string, isCreation: boolean): string {
  const params: Record<string, string> = { date: formatISOString(at, 'dd/MM/yyyy HH:mm'), name: by };
  if (isCreation) return I18n.insert(by ? I18n.labels.SHARED.DETAIL.CREATED_AT_BY : I18n.labels.SHARED.DETAIL.CREATED_AT, params);
  return I18n.insert(by ? I18n.labels.SHARED.DETAIL.UPDATED_AT_BY : I18n.labels.SHARED.DETAIL.UPDATED_AT, params);
}

const Timestamps: FC<TProps> = ({ entity }) => {
  const { createdAt, createdBy, updatedAt, updatedBy } = entity;
  return (
    <div className="timestamps">
      {createdAt && <div>{formatTimestamp(createdAt, createdBy, true)}</div>}
      {updatedAt && <div>{formatTimestamp(updatedAt, updatedBy, false)}</div>}
    </div>
  );
};

export default Timestamps;
