import { useState } from 'react';
import { TFormValidationErrors } from '../_hooks/useForm';
import { Button, Icon, InputField } from '../_shared';
import { TAvailabilityForm } from './_models/AvailabilityForm';
import { AvailabilitySlot } from './_models/AvailabilitySlot';

type TProps = {
  addRow?: () => void;
  beginTime: string;
  dayOfWeek: number;
  deleteRow?: (index: number) => void;
  endTime: string;
  hasValidationErrors: boolean;
  id: number;
  step: number;
  updateForm: (updatedAvailabilitySlot: AvailabilitySlot) => void;
  validationErrors?: TFormValidationErrors<TAvailabilityForm>;
};

const AvailabilityRow: React.FC<TProps> = ({ id, dayOfWeek, updateForm, addRow, deleteRow, ...props }) => {
  const [beginTime, setBeginTime] = useState(props.beginTime);
  const [endTime, setEndTime] = useState(props.endTime);

  const validationTime = props.hasValidationErrors && props.validationErrors.availabilities[dayOfWeek].times[id];

  const validationBeginTime = validationTime && validationTime.beginTime;
  const validationEndTime = validationTime && validationTime.endTime;

  return (
    <div className="availability-row">
      <InputField
        name={'beginTimeInput'}
        onChange={async (value: string) => {
          setBeginTime(value);
          updateForm({
            availabilityTime: { beginTime: value, endTime },
            id,
          });
        }}
        step={props.step}
        type="time"
        validation={validationBeginTime}
        value={props.beginTime}
      />
      <span className="dash">-</span>
      <InputField
        name={'endTimeInput'}
        onChange={async (value: string) => {
          setEndTime(value);
          updateForm({
            availabilityTime: { beginTime, endTime: value },
            id,
          });
        }}
        step={props.step}
        type="time"
        validation={validationEndTime}
        value={props.endTime}
      />
      {addRow && (
        <Button
          className="addRowButton"
          onClick={() => {
            if (addRow) addRow();
          }}
          theme="primary"
        >
          <Icon name="SvgAdd" />
        </Button>
      )}
      {deleteRow && (
        <Button
          className="deleteRowButton"
          onClick={() => {
            if (deleteRow) deleteRow(id);
          }}
          theme="primary"
        >
          <Icon name="SvgTrash" />
        </Button>
      )}
    </div>
  );
};

export default AvailabilityRow;
