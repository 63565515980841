import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAuthenticationContext } from '../../_context';
import { TApiError, HttpClient } from '../../_http';

function logout(): Promise<void> {
  return HttpClient.post('auth/logout');
}

export function useLogout() {
  const history = useHistory();
  const { clearProfile } = useAuthenticationContext();

  return useMutation<void, TApiError>('logout', logout, {
    onSuccess: () => {
      clearProfile();
      history.push('auth/login');
    },
  });
}
