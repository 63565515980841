import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuthenticationContext } from '../../_context';

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useAuthenticationContext();
  const { pathname } = useLocation();

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={{ pathname: '/auth/login', state: { pathname } }} />
      }
    />
  );
};

export default AuthorizedRoute;
