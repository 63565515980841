import { FC, ChangeEvent, ReactNode } from 'react';
import classnames from 'classnames';
import InputWrapper, { TInputWrapperProps } from '../InputWrapper';
import { useInputError } from '../../../_hooks';
import './inputField.scss';

export type TInputFieldProps = TInputWrapperProps & {
  autoComplete?: string;
  autoFocus?: boolean;
  icon?: ReactNode;
  normalize?: (value: string) => string | number;
  onChange: (value: string | number, name: string) => void;
  onClick?: () => void;
  placeholder?: string;
  step?: string | number;
  type?: 'email' | 'number' | 'password' | 'text' | 'time';
  value?: string;
};

const InputField: FC<TInputFieldProps> = ({
  autoComplete,
  autoFocus,
  icon,
  normalize,
  onChange,
  onClick = () => {},
  placeholder,
  step,
  type = 'text',
  value,
  ...wrapperProps
}) => {
  const { disabled, validation, name } = wrapperProps;
  const { setDirty, showError } = useInputError(validation);

  return (
    <InputWrapper {...wrapperProps} showError={showError}>
      <input
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={classnames('input', { error: showError })}
        disabled={disabled}
        name={name}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          let normalizedValue = type === 'number' ? parseFloat(value) : value.trim();
          if (normalize) normalizedValue = normalize(value.trim());
          onChange(normalizedValue, name);
          setDirty();
        }}
        onClick={onClick}
        placeholder={placeholder}
        step={step}
        type={type}
        value={value}
      />
      {icon}
    </InputWrapper>
  );
};

export default InputField;
