import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { HttpClient, TApiError } from '../../_http';
import { BookMeetingRequest } from '../_models/BookMeetingRequest';

function bookMeeting(body: BookMeetingRequest): Promise<void> {
  return HttpClient.post('meetings', body);
}

export function useBookMeeting() {
  const history = useHistory();

  return useMutation<void, TApiError, BookMeetingRequest>('bookMeeting', bookMeeting, {
    onSuccess: () => {
      history.push('/meetingbooked');
    },
  });
}
