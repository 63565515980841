export type TTranslationsNl = {
  AUTH: {
    SIDEBAR_TITLE: 'Admin portaal';
    LOGIN: {
      FORGOT_PASSWORD: 'Wachtwoord vergeten?';
      LOGIN: 'Aanmelden';
      PASSWORD: 'Wachtwoord';
      TITLE: 'Welkom!';
      USERNAME: 'Gebruikersnaam';
    };
    LOGOUT: 'Afmelden';
    PASSWORD_GUIDELINES: 'Wachtwoord vereisten: min. 8 karakters, tenminste 1 hoofdletter, 1 kleine letter, en 1 nummer.';
    REGISTER: { REGISTER: 'Stel een wachtwoord in'; TITLE: 'Welkom!' };
    RESET_PASSWORD: { RESET: 'Reset wachtwoord'; TITLE: 'Kies jouw nieuw wachtwoord' };
    REQUEST_PASSWORD_RESET: {
      DESCRIPTION: 'Geef het emailadres dat je gebruikte om te registreren en we zullen je via mail instructies toesturen om jouw wachtwoord opnieuw in te stellen';
      RESET: 'Verzend reset instructies';
      TITLE: 'Uw wachtwoord vergeten?';
    };
    CHANGE_PASSWORD: {
      TITLE: 'Verander wachtwoord';
      OLD_PASSWORD: 'Oud wachtwoord';
      NEW_PASSWORD: 'Nieuw wachtwoord';
      REPEAT_NEW_PASSWORD: 'Herhaal nieuw wachtwoord';
    };
    ERRORS: {
      UNAUTHORIZED: 'Incorrecte gebruikersnaam or wachtwoord';
      USER_STATE_NOT_ALLOWED: 'Dit account is niet actief, waardoor je niet kan inloggen. Contacteer jouw administrator.';
      RESET_TOKEN_INVALID: 'De link die je gebruikt is fout.';
      RESET_TOKEN_EXPIRED: 'De link die je gebruikt is vervallen.';
      INVALID_OLD_PASSWORD: 'Het wachtwoord dat je ingaf is niet jouw huidig wachtwoord.';
    };
    TOASTER: {
      CHOOSE_PASSWORD: 'Je kan nu inloggen met jouw nieuw wachtwoord.';
      REQUEST_PASSWORD_RESET: 'Een email met instructies werd verzonden als het gelinkt is aan een account.';
      CHANGE_PASSWORD: 'Jouw wachtwoord is succesvol aangepast.';
    };
  };
  PROFILE: {
    TITLE: 'Welkom op het {{brandName}} admin portaal!';
    CHANGE_PASSWORD: {
      TITLE: 'Verander wachtwoord';
      DESCRIPTION: 'Van zodra je jouw wachtwoord hebt aangepast, kan je niet meer inloggen met het oude wachtwoord.';
    };
    DEV_MODE: {
      TITLE: 'Development modus';
      DESCRIPTION: 'Als je de development modus aanzet, zal je de vertalingssleutels te zien krijgen. Zo een sleutel is nodig om de vertaling te wijzigen.';
      BUTTON: { TURN_ON: 'Zet aan'; TURN_OFF: 'Zet af' };
    };
    LOCALE: { TITLE: 'Taal'; DESCRIPTION: 'Jouw huidige taal is <strong>{{locale}}</strong>'; BUTTON: 'Vertaal naar {{locale}}' };
  };
  ROLES: {
    NAME: 'Naam';
    PERMISSIONS: {
      TITLE: 'Rechten';
      FEATURES: { ROLES: 'Rollen'; USERS: 'Gebruikers' };
      RIGHTS: { EDIT: 'Pas aan'; VIEW: 'Bekijk' };
    };
    OVERVIEW: {
      CREATE_ROLE: 'Maak rol aan';
      CREATED_AT: 'Aangemaakt op';
      EMPTY: 'Geen rollen gevonden';
      TITLE: 'Rollen';
      UPDATED_AT: 'Aangepast op';
    };
    CREATE: { TITLE: 'Maak rol aan' };
    DETAIL: { BACK: 'Terug naar rollen'; BUTTON_DELETE: 'Verwijder rol'; LOADING: 'Rol inladen...' };
    DELETE: {
      CONTENT: 'Bent u zeker dat u deze rol wilt verwijderen? Dit kan falen als de rol nog in gebruik is.';
      CONFIRM: 'Verwijder rol';
    };
    ERRORS: {
      ROLE_IN_USE: 'Deze rol is nog steeds in gebruik. Daarom is het niet mogelijk om deze te verwijderen.';
      ROLE_NAME_ALREADY_IN_USE: 'Een rol met deze naam bestaat al.';
    };
    TOASTER: {
      ROLE_CREATED: 'De rol werd succesvol aangemaakt.';
      ROLE_UPDATED: 'De aanpassingen zijn succesvol toegepast.';
      ROLE_DELETED: 'De rol werd succesvol verwijderd.';
    };
  };
  SHARED: {
    PROFILE: { TITLE: 'Welkom om het {{brandName}} admin portaal!' };
    BUTTONS: { CANCEL: 'Annuleren'; CREATE: 'Aanmaken'; DELETE: 'Verwijderen'; SAVE: 'Wijzigingen opslaan' };
    DETAIL: {
      CREATED_AT: 'Aangemaakt op {{date}}';
      CREATED_AT_BY: 'Aangemaakt op {{date}} door {{name}}';
      UPDATED_AT: 'Laatst aangepast op {{date}}';
      UPDATED_AT_BY: 'Laatst aangepast op {{date}} door {{name}}';
    };
    DROPDOWN: { OPTION_CLEAR_VALUE: '-- Waarde leegmaken --'; NO_OPTIONS: 'Geen opties beschikbaar' };
    FILE_UPLOAD: {
      TITLE: 'Sleep je bestanden hiernaar toe of klik om te zoeken op jouw computer';
      REQUIREMENTS: 'Toegelaten extensies: {{extensions}} - Maximum grootte: {{size}}';
    };
    NAVIGATION: { ROLES: 'Rollen'; USERS: 'Gebruikers' };
    LOCALE: { EN: 'Engels'; NL: 'Nederlands' };
    PLACEHOLDER: { DATE: 'dd/mm/jjjj'; SEARCH: 'Zoeken…'; TIME: 'uu:mm' };
    LOADING_APPLICATION: 'Application inladen...';
  };
  USERS: {
    EMAIL: 'Email';
    FIRST_NAME: 'Voornaam';
    LAST_NAME: 'Achternaam';
    ROLE: 'Rollen';
    DETAIL: {
      BACK: 'Terug naar gebruikers';
      BUTTON_DELETE: 'Gebruiker verwijderen';
      DETAILS: { TITLE: 'Details' };
      STATUS: {
        TITLE: 'Status';
        SUBTITLE: 'Deze gebruiker is momenteel';
        DESCRIPTION: {
          ACTIVE: 'De gebruiker kan inloggen.';
          INACTIVE: 'De gebruiker kan niet inloggen.';
          REGISTERING: 'Er is een email verzonden naar deze gebruiker om zijn/haar account te activeren door een wachtwoord te kiezen.';
        };
        BUTTON: {
          ACTIVATE: 'Activeer gebruiker';
          DEACTIVATE: 'Deactiveer gebruiker';
          RESEND_REGISTER_MAIL: 'Verzend email opnieuw';
        };
      };
      LOADING: 'Gebruiker inladen...';
    };
    OVERVIEW: {
      CREATE_USER: 'Maak gebruiker aan';
      EMPTY: 'Geen gebruiker gevonden';
      TITLE: 'Gebruikers';
      CREATED_AT: 'Aangemaakt op';
      CREATED_BY: 'Aangemaakt door';
      UPDATED_AT: 'Aangepast op';
      UPDATED_BY: 'Aangepast door';
    };
    CREATE: { TITLE: 'Maak gebruiker aan' };
    DEACTIVATE: {
      CONTENT: 'Bent u zeker dat u deze gebruiker wilt deactiveren? Door dit te doen zal de gebruiker niet meer kunnen inloggen.';
      CONFIRM: 'Deactivateer gebruiker';
    };
    DELETE: { CONTENT: 'Bent u zeker dat u deze gebruiker wilt verwijderen?'; CONFIRM: 'Verwijder gebruiker' };
    STATE: { TITLE: 'Status'; OPTIONS: { ACTIVE: 'Actief'; INACTIVE: 'Inactief'; REGISTERING: 'Registreren' } };
    ERRORS: { EMAIL_ALREADY_IN_USE: 'Een gebruiker met het gegeven emailadres bestaat al.' };
    TOASTER: {
      USER_CREATED: 'De gebruiker werd succesvol aangemaakt.';
      USER_UPDATED: 'De wijzigingen zijn succesvol opgeslagen.';
      USER_DELETED: 'De gebruiker werd succesvol verwijderd.';
    };
  };
  ERRORS: {
    GENERAL: 'Er ging iets mis. Probeer alstublieft opnieuw.';
    PERMISSION_DENIED: 'U hebt geen rechten om deze actie uit te voeren';
    VALIDATION: {
      AFTER_TIME: 'Tijdstip zou voor {{time}} moeten vallen';
      BEFORE_TIME: 'Tijdstip zou na {{time}} moeten vallen';
      AFTER_DATE: 'Datum zou na {{date}} moeten vallen';
      BEFORE_DATE: 'Datum zou voor {{date}} moeten vallen';
      EMPTY_ARRAY: 'Kies tenminste één';
      FORM: 'Sommige velden zijn niet (correct) ingevuld.';
      INVALID_NEW_AND_REPEATED_PASSWORD: 'Het nieuwe wachtwoord komt niet overeen met de herhaling.';
      INVALID: 'Waarde is niet correct.';
      MAX_LENGTH: 'Waarde zou tenminste {{length}} karakters moeten hebben.';
      MIN_LENGTH: 'Waarde zou maximum {{length}} karakters mogen hebben.';
      NOT_A_NUMBER: 'Waarde is geen nummer.';
      PASSWORD_TOO_SHORT: 'Dit wachtwoord is te kort';
      PASSWORD_UNSAFE: 'Dit wachtwoord voldoet niet aan de vereisten.';
      REQUIRED: 'Verplicht';
    };
  };
};

export const translationsNl: TTranslationsNl = {
  AUTH: {
    SIDEBAR_TITLE: 'Admin portaal',
    LOGIN: {
      FORGOT_PASSWORD: 'Wachtwoord vergeten?',
      LOGIN: 'Aanmelden',
      PASSWORD: 'Wachtwoord',
      TITLE: 'Welkom!',
      USERNAME: 'Gebruikersnaam',
    },
    LOGOUT: 'Afmelden',
    PASSWORD_GUIDELINES: 'Wachtwoord vereisten: min. 8 karakters, tenminste 1 hoofdletter, 1 kleine letter, en 1 nummer.',
    REGISTER: { REGISTER: 'Stel een wachtwoord in', TITLE: 'Welkom!' },
    RESET_PASSWORD: { RESET: 'Reset wachtwoord', TITLE: 'Kies jouw nieuw wachtwoord' },
    REQUEST_PASSWORD_RESET: {
      DESCRIPTION:
        'Geef het emailadres dat je gebruikte om te registreren en we zullen je via mail instructies toesturen om jouw wachtwoord opnieuw in te stellen',
      RESET: 'Verzend reset instructies',
      TITLE: 'Uw wachtwoord vergeten?',
    },
    CHANGE_PASSWORD: {
      TITLE: 'Verander wachtwoord',
      OLD_PASSWORD: 'Oud wachtwoord',
      NEW_PASSWORD: 'Nieuw wachtwoord',
      REPEAT_NEW_PASSWORD: 'Herhaal nieuw wachtwoord',
    },
    ERRORS: {
      UNAUTHORIZED: 'Incorrecte gebruikersnaam or wachtwoord',
      USER_STATE_NOT_ALLOWED: 'Dit account is niet actief, waardoor je niet kan inloggen. Contacteer jouw administrator.',
      RESET_TOKEN_INVALID: 'De link die je gebruikt is fout.',
      RESET_TOKEN_EXPIRED: 'De link die je gebruikt is vervallen.',
      INVALID_OLD_PASSWORD: 'Het wachtwoord dat je ingaf is niet jouw huidig wachtwoord.',
    },
    TOASTER: {
      CHOOSE_PASSWORD: 'Je kan nu inloggen met jouw nieuw wachtwoord.',
      REQUEST_PASSWORD_RESET: 'Een email met instructies werd verzonden als het gelinkt is aan een account.',
      CHANGE_PASSWORD: 'Jouw wachtwoord is succesvol aangepast.',
    },
  },
  PROFILE: {
    TITLE: 'Welkom op het {{brandName}} admin portaal!',
    CHANGE_PASSWORD: {
      TITLE: 'Verander wachtwoord',
      DESCRIPTION: 'Van zodra je jouw wachtwoord hebt aangepast, kan je niet meer inloggen met het oude wachtwoord.',
    },
    DEV_MODE: {
      TITLE: 'Development modus',
      DESCRIPTION:
        'Als je de development modus aanzet, zal je de vertalingssleutels te zien krijgen. Zo een sleutel is nodig om de vertaling te wijzigen.',
      BUTTON: { TURN_ON: 'Zet aan', TURN_OFF: 'Zet af' },
    },
    LOCALE: { TITLE: 'Taal', DESCRIPTION: 'Jouw huidige taal is <strong>{{locale}}</strong>', BUTTON: 'Vertaal naar {{locale}}' },
  },
  ROLES: {
    NAME: 'Naam',
    PERMISSIONS: {
      TITLE: 'Rechten',
      FEATURES: { ROLES: 'Rollen', USERS: 'Gebruikers' },
      RIGHTS: { EDIT: 'Pas aan', VIEW: 'Bekijk' },
    },
    OVERVIEW: {
      CREATE_ROLE: 'Maak rol aan',
      CREATED_AT: 'Aangemaakt op',
      EMPTY: 'Geen rollen gevonden',
      TITLE: 'Rollen',
      UPDATED_AT: 'Aangepast op',
    },
    CREATE: { TITLE: 'Maak rol aan' },
    DETAIL: { BACK: 'Terug naar rollen', BUTTON_DELETE: 'Verwijder rol', LOADING: 'Rol inladen...' },
    DELETE: {
      CONTENT: 'Bent u zeker dat u deze rol wilt verwijderen? Dit kan falen als de rol nog in gebruik is.',
      CONFIRM: 'Verwijder rol',
    },
    ERRORS: {
      ROLE_IN_USE: 'Deze rol is nog steeds in gebruik. Daarom is het niet mogelijk om deze te verwijderen.',
      ROLE_NAME_ALREADY_IN_USE: 'Een rol met deze naam bestaat al.',
    },
    TOASTER: {
      ROLE_CREATED: 'De rol werd succesvol aangemaakt.',
      ROLE_UPDATED: 'De aanpassingen zijn succesvol toegepast.',
      ROLE_DELETED: 'De rol werd succesvol verwijderd.',
    },
  },
  SHARED: {
    PROFILE: { TITLE: 'Welkom om het {{brandName}} admin portaal!' },
    BUTTONS: { CANCEL: 'Annuleren', CREATE: 'Aanmaken', DELETE: 'Verwijderen', SAVE: 'Wijzigingen opslaan' },
    DETAIL: {
      CREATED_AT: 'Aangemaakt op {{date}}',
      CREATED_AT_BY: 'Aangemaakt op {{date}} door {{name}}',
      UPDATED_AT: 'Laatst aangepast op {{date}}',
      UPDATED_AT_BY: 'Laatst aangepast op {{date}} door {{name}}',
    },
    DROPDOWN: { OPTION_CLEAR_VALUE: '-- Waarde leegmaken --', NO_OPTIONS: 'Geen opties beschikbaar' },
    FILE_UPLOAD: {
      TITLE: 'Sleep je bestanden hiernaar toe of klik om te zoeken op jouw computer',
      REQUIREMENTS: 'Toegelaten extensies: {{extensions}} - Maximum grootte: {{size}}',
    },
    NAVIGATION: { ROLES: 'Rollen', USERS: 'Gebruikers' },
    LOCALE: { EN: 'Engels', NL: 'Nederlands' },
    PLACEHOLDER: { DATE: 'dd/mm/jjjj', SEARCH: 'Zoeken…', TIME: 'uu:mm' },
    LOADING_APPLICATION: 'Application inladen...',
  },
  USERS: {
    EMAIL: 'Email',
    FIRST_NAME: 'Voornaam',
    LAST_NAME: 'Achternaam',
    ROLE: 'Rollen',
    DETAIL: {
      BACK: 'Terug naar gebruikers',
      BUTTON_DELETE: 'Gebruiker verwijderen',
      DETAILS: { TITLE: 'Details' },
      STATUS: {
        TITLE: 'Status',
        SUBTITLE: 'Deze gebruiker is momenteel',
        DESCRIPTION: {
          ACTIVE: 'De gebruiker kan inloggen.',
          INACTIVE: 'De gebruiker kan niet inloggen.',
          REGISTERING:
            'Er is een email verzonden naar deze gebruiker om zijn/haar account te activeren door een wachtwoord te kiezen.',
        },
        BUTTON: {
          ACTIVATE: 'Activeer gebruiker',
          DEACTIVATE: 'Deactiveer gebruiker',
          RESEND_REGISTER_MAIL: 'Verzend email opnieuw',
        },
      },
      LOADING: 'Gebruiker inladen...',
    },
    OVERVIEW: {
      CREATE_USER: 'Maak gebruiker aan',
      EMPTY: 'Geen gebruiker gevonden',
      TITLE: 'Gebruikers',
      CREATED_AT: 'Aangemaakt op',
      CREATED_BY: 'Aangemaakt door',
      UPDATED_AT: 'Aangepast op',
      UPDATED_BY: 'Aangepast door',
    },
    CREATE: { TITLE: 'Maak gebruiker aan' },
    DEACTIVATE: {
      CONTENT: 'Bent u zeker dat u deze gebruiker wilt deactiveren? Door dit te doen zal de gebruiker niet meer kunnen inloggen.',
      CONFIRM: 'Deactivateer gebruiker',
    },
    DELETE: { CONTENT: 'Bent u zeker dat u deze gebruiker wilt verwijderen?', CONFIRM: 'Verwijder gebruiker' },
    STATE: { TITLE: 'Status', OPTIONS: { ACTIVE: 'Actief', INACTIVE: 'Inactief', REGISTERING: 'Registreren' } },
    ERRORS: { EMAIL_ALREADY_IN_USE: 'Een gebruiker met het gegeven emailadres bestaat al.' },
    TOASTER: {
      USER_CREATED: 'De gebruiker werd succesvol aangemaakt.',
      USER_UPDATED: 'De wijzigingen zijn succesvol opgeslagen.',
      USER_DELETED: 'De gebruiker werd succesvol verwijderd.',
    },
  },
  ERRORS: {
    GENERAL: 'Er ging iets mis. Probeer alstublieft opnieuw.',
    PERMISSION_DENIED: 'U hebt geen rechten om deze actie uit te voeren',
    VALIDATION: {
      AFTER_TIME: 'Tijdstip zou voor {{time}} moeten vallen',
      BEFORE_TIME: 'Tijdstip zou na {{time}} moeten vallen',
      AFTER_DATE: 'Datum zou na {{date}} moeten vallen',
      BEFORE_DATE: 'Datum zou voor {{date}} moeten vallen',
      EMPTY_ARRAY: 'Kies tenminste één',
      FORM: 'Sommige velden zijn niet (correct) ingevuld.',
      INVALID_NEW_AND_REPEATED_PASSWORD: 'Het nieuwe wachtwoord komt niet overeen met de herhaling.',
      INVALID: 'Waarde is niet correct.',
      MAX_LENGTH: 'Waarde zou tenminste {{length}} karakters moeten hebben.',
      MIN_LENGTH: 'Waarde zou maximum {{length}} karakters mogen hebben.',
      NOT_A_NUMBER: 'Waarde is geen nummer.',
      PASSWORD_TOO_SHORT: 'Dit wachtwoord is te kort',
      PASSWORD_UNSAFE: 'Dit wachtwoord voldoet niet aan de vereisten.',
      REQUIRED: 'Verplicht',
    },
  },
};
