import { Switch, Redirect } from 'react-router-dom';
import { FC } from 'react';
import AuthorizedRoute from './_routing/routes/AuthorizedRoute';
import AuthorizedLayout from './_routing/layouts/authorized/AuthorizedLayout';
import UnauthorizedRoute from './_routing/routes/UnauthorizedRoute';
import UnauthorizedLayout from './_routing/layouts/unauthorized/UnauthorizedLayout';
import Toastify from './Toastify';
import ModalWrapper from './modal/ModalWrapper';
import { AuthenticationContextProvider } from './_context';

const App: FC = () => (
  <AuthenticationContextProvider>
    <Switch>
      <UnauthorizedRoute component={UnauthorizedLayout} path="/auth" />
      <AuthorizedRoute component={AuthorizedLayout} path="/" />
      <Redirect to="/" />
    </Switch>
    <ModalWrapper />
    <Toastify />
  </AuthenticationContextProvider>
);
export default App;
