import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { HttpClient, TApiError } from '../../_http';
import { TAvailabilitiesRequestBody } from '../_models/AvailabilitiesRequestBody';
import { transformOutgoingAvailabilities } from '../_utils/AvailabilitiesHelper';

function createAvailabilities({ body }: TAvailabilitiesRequestBody): Promise<void> {
  const availabilitiesDto = transformOutgoingAvailabilities({ body });
  return HttpClient.post('availabilities', availabilitiesDto);
}

export function useCreateAvailabilities() {
  const queryClient = useQueryClient();
  return useMutation<void, TApiError, TAvailabilitiesRequestBody>('createAvailabilities', createAvailabilities, {
    onSuccess: () => {
      toast.success('Successfully saved availabilities.');
      queryClient.invalidateQueries('createAvailabilities');
    },
  });
}
