import { FC } from 'react';
import Modal from '../modal/Modal';
import { ModalOpener } from '../modal/ModalOpener';
import { Button } from '../_shared';

const NoAvailabilitiesModal: FC = () => {
  return (
    <Modal>
      <Modal.Header>No availabilities</Modal.Header>
      <Modal.Content>This user has not set up their availabilities yet.</Modal.Content>
      <Modal.Buttons>
        <Button onClick={() => ModalOpener.instance.close()} theme="primary">
          OK
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};

export default NoAvailabilitiesModal;
