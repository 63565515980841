import { FC, useEffect } from 'react';
import { useToggle } from '../_hooks';
import { months } from './_utils/Months';

type TProps = {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
};

const CustomDatePickerHeader: FC<TProps> = ({ date, decreaseMonth, increaseMonth }: TProps) => {
  const [prevMonthButtonDisabled, setPrevMonthButtonDisabled] = useToggle(false);

  useEffect(() => {
    const now = new Date();
    setPrevMonthButtonDisabled(date.getMonth() === now.getMonth());
  }, [date]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <button className="prev-month" disabled={prevMonthButtonDisabled} onClick={decreaseMonth} type="button">
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous" />
      </button>
      <div className="react-datepicker__current-month">
        {months[date.getMonth()]} {date.getFullYear()}
      </div>
      <button className="next-month" disabled={false} onClick={increaseMonth} type="button">
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next" />
      </button>
    </div>
  );
};

export default CustomDatePickerHeader;
