import { FC, createContext, useContext, ReactNode, useState } from 'react';
import { useAuthenticate, useVerify } from '../auth/_queries';
import { TProfile } from '../profile/_models';
import { Spinner } from '../_shared';
import { I18n } from '../_translations';

type TAuthenticationContext = {
  clearProfile: () => void;
  isLoggedIn: boolean;
  profile?: TProfile;
  setProfile: (profile: TProfile) => void;
};

const AuthenticationContext = createContext<TAuthenticationContext>({
  clearProfile: () => {},
  isLoggedIn: false,
  setProfile: () => {},
});

export const useAuthenticationContext = () => useContext(AuthenticationContext);

type Props = {
  children: ReactNode;
};

export const AuthenticationContextProvider: FC<Props> = ({ children }) => {
  const [profile, setProfile] = useState<TProfile>(null);
  const { isLoading: authenticationLoading } = useAuthenticate({
    onError: () => setProfile(null),
    onSuccess: data => setProfile(data),
  });
  const { isLoading: verifyLoading } = useVerify({
    onError: () => setProfile(null),
    onSuccess: data => setProfile(data),
  });

  return (
    <AuthenticationContext.Provider
      value={{
        clearProfile: () => setProfile(null),
        isLoggedIn: !!profile,
        profile,
        setProfile: value => setProfile(value),
      }}
    >
      {authenticationLoading || verifyLoading ? <Spinner overlay>{I18n.labels.SHARED.LOADING_APPLICATION}</Spinner> : children}
    </AuthenticationContext.Provider>
  );
};
