import { Route, Redirect } from 'react-router-dom';
import { useAuthenticationContext } from '../../_context';

const UnauthorizedRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useAuthenticationContext();

  return <Route {...rest} render={props => (!isLoggedIn ? <Component {...props} /> : <Redirect to="/" />)} />;
};

export default UnauthorizedRoute;
