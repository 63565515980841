import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import { Config } from './config';
import * as serviceWorker from './serviceWorker';
import './index.scss';

// Setup sentry error logging
const environmentsWithErrorLogging = ['production', 'staging'];
const needsErrorLogging = Config.sentryDsn && environmentsWithErrorLogging.includes(Config.environment);
if (needsErrorLogging) {
  Sentry.init({
    dsn: Config.sentryDsn,
    environment: Config.environment,
  });
}

const queryClient = new QueryClient({ defaultOptions: Config.reactQuery });

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

serviceWorker.unregister();
