import { useEffect, useState } from 'react';
import { useToggle } from '../_hooks';
import { TFormValidationErrors } from '../_hooks/useForm';
import { Checkbox } from '../_shared';
import AvailabilityRow from './AvailabilityRow';
import { AvailabilityTime } from './_models/Availability';
import { TAvailabilityForm } from './_models/AvailabilityForm';
import { AvailabilitySlot } from './_models/AvailabilitySlot';

type TProps = {
  dayName: string;
  dayOfWeek: number;
  getAvailabilitySlots: (values: TAvailabilityForm, weekday: number) => AvailabilitySlot[];
  hasValidationErrors: boolean;
  isAvailable: boolean;
  step: number;
  updateForm: (availabilityTimes: AvailabilityTime[], dayOfWeek: number) => void;
  validationErrors?: TFormValidationErrors<TAvailabilityForm>;
  values: TAvailabilityForm;
};

const AvailabilityDay: React.FC<TProps> = ({ dayName, dayOfWeek, updateForm, ...props }) => {
  const [isAvailable, setIsAvailable] = useToggle(props.isAvailable);
  const [availabilitySlots, setAvailabilitySlots] = useState<AvailabilitySlot[]>([]);

  useEffect(() => {
    setAvailabilitySlots(props.getAvailabilitySlots(props.values, dayOfWeek));
  }, []);

  useEffect(() => {
    const times = availabilitySlots.map(s => s.availabilityTime);
    updateForm(times, dayOfWeek);
  }, [availabilitySlots]);

  function updateAvailabilities(updatedAvailability: AvailabilitySlot) {
    availabilitySlots[updatedAvailability.id] = updatedAvailability;
    setAvailabilitySlots([...availabilitySlots]);
  }

  function onChangeCheckbox() {
    if (isAvailable) {
      setAvailabilitySlots([]);
      updateForm([], dayOfWeek);
    } else {
      addAvailabilityRow();
    }
    setIsAvailable(!isAvailable);
  }

  function addAvailabilityRow() {
    const newAvailabilitySlotId = availabilitySlots.length;
    const availabilityTime: AvailabilityTime = {
      beginTime: '',
      endTime: '',
    };
    const availabilitySlot: AvailabilitySlot = { availabilityTime, id: newAvailabilitySlotId };
    const times = availabilitySlots.map(s => s.availabilityTime);
    updateForm([...times, availabilityTime], dayOfWeek);
    setAvailabilitySlots([...availabilitySlots, availabilitySlot]);
  }

  function deleteAvailabilityRow(id: number) {
    const availabilityTimes = availabilitySlots.map(a => a.availabilityTime);
    updateForm(availabilityTimes, dayOfWeek);

    const filteredSlots = availabilitySlots.filter(slot => slot.id !== id);
    filteredSlots.map((slot, i) => {
      filteredSlots[i] = { availabilityTime: slot.availabilityTime, id: i };
    });
    setAvailabilitySlots(filteredSlots);
  }

  return (
    <div className="day">
      <Checkbox checked={isAvailable} className="checkbox-available" name={''} onChange={onChangeCheckbox} />
      <h3 className="dayName">{dayName}</h3>
      {isAvailable ? (
        <div className="rows">
          {availabilitySlots.slice(0, 1).map(slot => (
            <AvailabilityRow
              addRow={addAvailabilityRow}
              beginTime={slot.availabilityTime.beginTime}
              dayOfWeek={dayOfWeek}
              endTime={slot.availabilityTime.endTime}
              hasValidationErrors={props.hasValidationErrors}
              id={slot.id}
              key={slot.id}
              step={props.step}
              updateForm={updateAvailabilities}
              validationErrors={props.validationErrors}
            />
          ))}

          {availabilitySlots.slice(1).map(slot => (
            <AvailabilityRow
              beginTime={slot.availabilityTime.beginTime}
              dayOfWeek={dayOfWeek}
              deleteRow={deleteAvailabilityRow}
              endTime={slot.availabilityTime.endTime}
              hasValidationErrors={props.hasValidationErrors}
              id={slot.id}
              key={slot.id}
              step={props.step}
              updateForm={updateAvailabilities}
              validationErrors={props.validationErrors}
            />
          ))}
        </div>
      ) : (
        <span className="unavailable">Unavailable</span>
      )}
    </div>
  );
};

export default AvailabilityDay;
