import { FC } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Auth from '../../../auth/Auth';
import Logo from '../../../_assets/svg/logo.svg';
import './unauthorizedLayout.scss';

const UnauthorizedLayout: FC = () => (
  <div className="unauthorized-layout">
    <aside>
      <img alt="icapps logo" src={Logo} />
    </aside>
    <Switch>
      <Route component={Auth} path="/auth" />
      <Redirect to="/auth" />
    </Switch>
  </div>
);

export default UnauthorizedLayout;
