import { Availability, AvailabilityTime } from '../_models/Availability';

const emptyTimes: AvailabilityTime = { beginTime: '', endTime: '' };

export const emptyAvailabilities: Availability[] = [
  { times: [emptyTimes] },
  { times: [emptyTimes] },
  { times: [emptyTimes] },
  { times: [emptyTimes] },
  { times: [emptyTimes] },
  { times: [emptyTimes] },
  { times: [emptyTimes] },
];

export const emptyAvailabilitiesNoTimes: Availability[] = [
  { times: [] },
  { times: [] },
  { times: [] },
  { times: [] },
  { times: [] },
  { times: [] },
  { times: [] },
];
