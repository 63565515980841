import { useQuery } from 'react-query';
import { HttpClient, TApiError } from '../../_http';
import { AvailableDaysResponse } from '../_models/AvailableDaysResponse';

function fixJsonDates(data: AvailableDaysResponse): AvailableDaysResponse {
  const tempArray = new Array<Date>();
  data.dates.forEach(date => {
    tempArray.push(new Date(date));
  });
  data.dates = tempArray;
  return data;
}

function getAvailableDays(userId: string, month: number, year: number): Promise<AvailableDaysResponse> {
  return HttpClient.get<AvailableDaysResponse>('availabilities/availableDays', { month, userId, year });
}

export function useGetAvailableDays(userId: string, month: number, year: number) {
  return useQuery<AvailableDaysResponse, TApiError>('getAvailableDays', () => getAvailableDays(userId, month, year), {
    select: fixJsonDates,
  });
}
