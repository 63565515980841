import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Button, InputField, ErrorMessage } from '../../_shared';
import { useForm } from '../../_hooks';
import { I18n } from '../../_translations';
import { formValidator } from '../../_utils/formValidation';
import { TFormValidationErrors } from '../../_hooks/useForm';
import { TChoosePasswordForm } from '../_models';
import { TApiError } from '../../_http';
import './choosePassword.scss';
import { useChoosePassword } from '../_queries';

type TProps = {
  isPasswordReset?: boolean;
};

const initialForm: TChoosePasswordForm = {
  newPassword: '',
};

function validateForm(values: TChoosePasswordForm): TFormValidationErrors<TChoosePasswordForm> {
  return {
    newPassword: formValidator.password(values.newPassword),
  };
}

function errorAsString(error?: TApiError): string {
  if (error?.error === 'RESET_TOKEN_INVALID') return I18n.labels.AUTH.ERRORS.RESET_TOKEN_INVALID;
  if (error?.error === 'RESET_TOKEN_EXPIRED') return I18n.labels.AUTH.ERRORS.RESET_TOKEN_EXPIRED;
  return null;
}

const ChoosePassword: FC<TProps> = ({ isPasswordReset }) => {
  const { isLoading, error, mutate: choosePassword } = useChoosePassword();
  const { token } = useParams<{ token: string }>();
  const form = useForm<TChoosePasswordForm>({
    error,
    initialForm,
    submitForm: values => choosePassword({ ...values, resetToken: token }),
    validateForm,
  });
  const errorMessage = errorAsString(error);

  return (
    <main className="right-container choose-password">
      <h1>{isPasswordReset ? I18n.labels.AUTH.RESET_PASSWORD.TITLE : I18n.labels.AUTH.REGISTER.TITLE}</h1>
      <p>{I18n.labels.AUTH.PASSWORD_GUIDELINES}</p>
      <form onSubmit={form.submit}>
        <ErrorMessage isGlobal isVisible={!!errorMessage}>
          {errorMessage}
        </ErrorMessage>
        <InputField
          autoComplete="new-password"
          name="newPassword"
          onChange={form.setAttribute}
          type="password"
          validation={form.validationErrors.newPassword}
          value={form.values.newPassword}
        />
        <div className="actions">
          <Button loading={isLoading} theme="primary" type="submit">
            {isPasswordReset ? I18n.labels.AUTH.RESET_PASSWORD.RESET : I18n.labels.AUTH.REGISTER.REGISTER}
          </Button>
        </div>
      </form>
    </main>
  );
};

export default ChoosePassword;
