import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { TProfile } from '../../profile/_models';
import { useAuthenticationContext } from '../../_context';
import { TApiError, HttpClient } from '../../_http';
import { TLoginForm } from '../_models';

function login(body: TLoginForm): Promise<TProfile> {
  return HttpClient.post<TProfile>('auth/login', body);
}

export function useLogin() {
  const history = useHistory();
  const { setProfile } = useAuthenticationContext();

  return useMutation<TProfile, TApiError, TLoginForm>('login', login, {
    onSuccess: data => {
      setProfile(data);
      history.replace('/');
    },
  });
}
