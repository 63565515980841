import { FC, useEffect, useState } from 'react';
import { formatDate } from '../_utils/dateHelpers';

type TProps = {
  date: Date;
  timeslots: Date[];
  updateTimeslot: (timeslot: Date) => void;
};

const AvailableTimeSlots: FC<TProps> = ({ date, timeslots, updateTimeslot }: TProps) => {
  const [times, setTimes] = useState<Date[]>([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState<number>();

  function handleClick(timeslot: Date, i: number) {
    setSelectedTimeslot(i);
    updateTimeslot(timeslot);
  }

  useEffect(() => {
    setTimes(timeslots);
    // reset selected timeslot when date changes
    setSelectedTimeslot(-1);
  }, [timeslots, date]);

  return (
    <div className="timeslots-container">
      {!!timeslots.length && (
        <div className="timeslots-box">
          <div className="timeslots-header">{formatDate(new Date(timeslots[0]), 'EEEE d MMM yyyy')}</div>
          <ul className="timeslots-list">
            {times.map((timeslot, i) => (
              <li
                className={`timeslots-listitem${selectedTimeslot === i ? ' selected' : ''}`}
                key={timeslot.valueOf()}
                onClick={() => handleClick(timeslot, i)}
              >
                {formatDate(new Date(timeslot), 'HH:mm')}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AvailableTimeSlots;
