import { Button, InputField } from '../../_shared';
import { useForm } from '../../_hooks';
import { I18n } from '../../_translations';
import { TFormValidationErrors } from '../../_hooks/useForm';
import { formValidator } from '../../_utils/formValidation';
import { TRequestPasswordResetForm } from '../_models';
import './requestPasswordReset.scss';
import { useRequestPasswordReset } from '../_queries';

const initialForm: TRequestPasswordResetForm = {
  email: '',
};

function validateForm(values: TRequestPasswordResetForm): TFormValidationErrors<TRequestPasswordResetForm> {
  return {
    email: formValidator.email(values.email),
  };
}

const RequestPasswordReset = () => {
  const { isLoading, mutate: requestPasswordReset } = useRequestPasswordReset();
  const form = useForm<TRequestPasswordResetForm>({
    initialForm,
    submitForm: values => requestPasswordReset(values),
    validateForm,
  });

  return (
    <main className="right-container request-password-reset">
      <h1>{I18n.labels.AUTH.REQUEST_PASSWORD_RESET.TITLE}</h1>
      <p>{I18n.labels.AUTH.REQUEST_PASSWORD_RESET.DESCRIPTION}</p>
      <form onSubmit={form.submit}>
        <InputField
          autoComplete="email"
          name="email"
          onChange={form.setAttribute}
          type="email"
          validation={form.validationErrors.email}
          value={form.values.email}
        />
        <div className="actions">
          <Button loading={isLoading} theme="primary" type="submit">
            {I18n.labels.AUTH.REQUEST_PASSWORD_RESET.RESET}
          </Button>
        </div>
      </form>
    </main>
  );
};

export default RequestPasswordReset;
