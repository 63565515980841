/* eslint-disable sort-keys-fix/sort-keys-fix */
export const WeekDays = {
  MON: 0,
  TUE: 1,
  WED: 2,
  THU: 3,
  FRI: 4,
  SAT: 5,
  SUN: 6,
};
