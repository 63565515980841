import { dateFromTime, formatDate } from '../../_utils/dateHelpers';
import { AvailabilitiesDto } from '../_models/AvailabilitiesDto';
import { TAvailabilitiesRequestBody } from '../_models/AvailabilitiesRequestBody';
import { Availability } from '../_models/Availability';
import { AvailabilityDto } from '../_models/AvailabilityDto';
import { emptyAvailabilitiesNoTimes } from './EmptyAvailabilities';
import { WeekDays } from './WeekDays';

export function transformIncomingAvailabilities(data: AvailabilityDto[]): Availability[] {
  if (!data.length) {
    return null;
  }
  const availabilities: Availability[] = emptyAvailabilitiesNoTimes;

  data.map(availability => {
    const startTime = formatDate(new Date(availability.startTime), 'HH:mm');
    const endTime = formatDate(new Date(availability.endTime), 'HH:mm');
    availabilities[availability.weekday].times.push({ beginTime: startTime, endTime: endTime });
  });
  return availabilities;
}

export function transformOutgoingAvailabilities({ body }: TAvailabilitiesRequestBody): AvailabilitiesDto {
  const availabilitiesDto: AvailabilitiesDto = { availabilityDtos: [] };
  body.availabilities.map(availability => {
    availability.times.map(time => {
      const availabilityRequestBody = {
        endTime: dateFromTime(time.endTime),
        startTime: dateFromTime(time.beginTime),
        weekday: body.availabilities.indexOf(availability).toString(),
      };
      availabilitiesDto.availabilityDtos.push(availabilityRequestBody);
    });
  });
  return availabilitiesDto;
}

export function transformAvailabilitiesPut({ body }: TAvailabilitiesRequestBody): AvailabilitiesDto {
  const availabilities: AvailabilitiesDto = transformOutgoingAvailabilities({ body });

  Object.values(WeekDays).forEach(i => {
    if (availabilities.availabilityDtos.filter(a => a.weekday === i.toString()).length === 0) {
      const emptyAvailabilityDto: AvailabilityDto = {
        endTime: new Date(0),
        startTime: new Date(0),
        weekday: i.toString(),
      };
      availabilities.availabilityDtos.push(emptyAvailabilityDto);
    }
  });

  return availabilities;
}
