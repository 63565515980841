import { useMutation } from 'react-query';
import { useLogout } from '../../auth/_queries';
import { HttpClient } from '../../_http';

function deleteAccount(id: string) {
  return HttpClient.delete(`users/${id}`);
}

export function useDeleteAccount() {
  const { mutate: logout } = useLogout();

  return useMutation('deleteAccount', deleteAccount, {
    onSuccess: () => logout(),
  });
}
