import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { TApiError, HttpClient } from '../../_http';
import { I18n } from '../../_translations';
import { TChoosePasswordRequestBody } from '../_models';

function choosePassword(body: TChoosePasswordRequestBody): Promise<void> {
  return HttpClient.post('auth/reset-password', body);
}

export function useChoosePassword() {
  const history = useHistory();

  return useMutation<void, TApiError, TChoosePasswordRequestBody>('choosePassword', choosePassword, {
    onSuccess: () => {
      toast.success(I18n.labels.AUTH.TOASTER.CHOOSE_PASSWORD);
      history.push('/auth/login');
    },
  });
}
