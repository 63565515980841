import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { HttpClient, TApiError } from '../../_http';
import { removeEmptyKeys } from '../../_utils/objectHelpers';
import { TAvailabilitiesRequestBody } from '../_models/AvailabilitiesRequestBody';
import { transformAvailabilitiesPut } from '../_utils/AvailabilitiesHelper';

function updateAvailabilities({ body }: TAvailabilitiesRequestBody): Promise<void> {
  const availabilitiesDto = transformAvailabilitiesPut({ body });

  return HttpClient.put(`availabilities`, removeEmptyKeys(availabilitiesDto));
}

export function useUpdateAvailabilities() {
  return useMutation<void, TApiError, TAvailabilitiesRequestBody>('updateAvailabilities', updateAvailabilities, {
    onSuccess: () => {
      toast.success('Successfully updated availabilities');
    },
  });
}
