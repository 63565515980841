import { FC } from 'react';
import { GoBackLink, Icon } from '../_shared';
import './MeetingBooked.scss';

const MeetingBooked: FC = () => {
  return (
    <div className="meetingbooked-box">
      <GoBackLink label="Back" to="/" />
      <div className="meetingbooked-container">
        <Icon name="SvgCheckmark" size={8} />
        <div className="meetingbooked-textwrapper">
          <div className="meetingbooked-header">Meeting booked!</div>
          <div className="meetingbooked-text">
            The meeting has been booked. Check your inbox for a confirmation-email. There you can also find a .ics file to add the
            meeting to your personal calendar.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingBooked;
