import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Datepicker, ErrorMessage } from '../_shared';
import { ModalOpener } from '../modal/ModalOpener';
import AvailableTimeSlots from './AvailableTimeSlots';
import MeetingConfirm from './MeetingConfirm';
import { useGetAvailableDays } from './_queries/useGetAvailableDays';
import CustomDatePickerHeader from './CustomDatePickerHeader';
import { useGetAvailableTimes } from './_queries/useGetAvailableTimes';
import NoAvailabilitiesModal from './NoAvailabilitiesModal';
import './Calendar.scss';

function getFirstAvailableDay(dates: Date[]) {
  const today = new Date();
  return dates.find(d => d.getDate() >= today.getDate());
}

const Calendar: FC = () => {
  const { hostId } = useParams<{ hostId: string }>();
  const [fetchMonth, setFetchMonth] = useState<Date>(new Date());
  const { isLoading, data, isError, refetch } = useGetAvailableDays(
    hostId,
    fetchMonth.getUTCMonth() + 1, // BE call from 1 not 0
    fetchMonth.getFullYear(),
  );
  const [selectedTimeslot, setSelectedTimeslot] = useState<Date>();
  const [date, setDate] = useState<Date>(new Date(new Date().setHours(0, 0, 0, 0)));
  const { data: availableTimes, refetch: refetchTimes } = useGetAvailableTimes(hostId, date);

  function fetchNextMonth(value: Date) {
    data.dates = [];
    setFetchMonth(value);
  }

  useEffect(() => {
    setSelectedTimeslot(null);
    refetchTimes();
  }, [date]);

  useEffect(() => {
    if (isError) {
      ModalOpener.initialize(() => <NoAvailabilitiesModal />);
    } else if (data) {
      if (data.dates.length > 0) {
        // on first load, set first available day as the selected date
        const availableDay = getFirstAvailableDay(data.dates);
        setDate(new Date(availableDay.setHours(0, 0, 0, 0)));
      }
    }
  }, [isLoading]);

  useEffect(() => {
    refetch();
  }, [fetchMonth]);

  return (
    <div className="calendar">
      <ErrorMessage isVisible={isError}>This user has not set up their availabilities.</ErrorMessage>
      {!isLoading && !isError && (
        <div className="calendar-box">
          <h2 className="calendar-header">Select a timeslot</h2>
          <div className="calendar-container">
            <Datepicker
              calendarStartDay={1}
              dayClassName={day => (day.valueOf() == date.valueOf() ? 'selected' : undefined)}
              disabledKeyboardNavigation
              includeDates={data ? data.dates : []}
              inline
              minDate={new Date()}
              onChange={(value: Date) => setDate(value)}
              onMonthChange={fetchNextMonth}
              renderCustomHeader={({ decreaseMonth, increaseMonth }) => (
                <CustomDatePickerHeader date={fetchMonth} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />
              )}
              shouldCloseOnSelect={false}
              useWeekdaysShort
              value={date.toDateString()}
            />
            <AvailableTimeSlots
              date={date}
              timeslots={availableTimes ? availableTimes.timeslots : []}
              updateTimeslot={setSelectedTimeslot}
            />
          </div>
          <MeetingConfirm date={selectedTimeslot} hostId={hostId} />
        </div>
      )}
    </div>
  );
};

export default Calendar;
