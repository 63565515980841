import { useQuery, UseQueryOptions } from 'react-query';
import { TProfile } from '../../profile/_models';
import { TApiError, HttpClient } from '../../_http';

function authenticate(): Promise<TProfile> {
  return HttpClient.get('auth/me');
}

export function useAuthenticate(options: UseQueryOptions<TProfile, TApiError>) {
  return useQuery<TProfile, TApiError>('authenticate', authenticate, { ...options, retry: false });
}
