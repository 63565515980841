import { FC } from 'react';
import { Button } from '../_shared';
import { formValidator } from '../_utils/formValidation';
import useForm, { TFormValidationErrors, TSubmitFormFunction } from '../_hooks/useForm';
import AvailabilityDay from './AvailabilityDay';
import { AvailabilityTime } from './_models/Availability';
import { TAvailabilityForm } from './_models/AvailabilityForm';
import { WeekDays } from './_utils/WeekDays';
import { AvailabilitySlot } from './_models/AvailabilitySlot';

function validateForm(values: TAvailabilityForm): TFormValidationErrors<TAvailabilityForm> {
  return {
    availabilities: values.availabilities.map(availability => {
      return {
        times: availability.times.map(time => {
          return {
            beginTime: formValidator.required(time.beginTime).isValid
              ? formValidator.beforeTimeString(time.beginTime, time.endTime)
              : formValidator.required(time.beginTime),
            endTime: formValidator.required(time.endTime).isValid
              ? formValidator.afterTimeString(time.endTime, time.beginTime)
              : formValidator.required(time.endTime),
          };
        }),
      };
    }),
  };
}

function isAvailable(form: TAvailabilityForm, weekday: number): boolean {
  if (form.availabilities.length > 0) {
    return Boolean(form.availabilities[weekday].times[0]);
  }
  return true;
}

function getAvailabilitySlots(values: TAvailabilityForm, weekday: number): AvailabilitySlot[] {
  const availabilitySlots: AvailabilitySlot[] = [];
  values.availabilities[weekday].times.map((time, i) => {
    const availabilityTime: AvailabilityTime = { beginTime: time.beginTime, endTime: time.endTime };
    const availabilitySlot: AvailabilitySlot = { availabilityTime, id: i };
    availabilitySlots.push(availabilitySlot);
  });
  return availabilitySlots;
}

type TProps = {
  initialForm: TAvailabilityForm;
  isLoading: boolean;
  step: number;
  submitForm: TSubmitFormFunction<TAvailabilityForm>;
};

const AvailabilityForm: FC<TProps> = ({ initialForm, submitForm, isLoading, step }: TProps) => {
  const form = useForm<TAvailabilityForm>({
    initialForm,
    submitForm: submitForm,
    validateForm,
  });

  function updateForm(availabilityTimes: AvailabilityTime[], dayOfWeek: number) {
    form.setValues((values: TAvailabilityForm) => (values.availabilities[dayOfWeek].times = availabilityTimes));
  }

  return (
    <div>
      {!isLoading && (
        <form onSubmit={form.submit}>
          <div className="days">
            {Object.entries(WeekDays).map(([key, value]) => (
              <AvailabilityDay
                dayName={key}
                dayOfWeek={value}
                getAvailabilitySlots={getAvailabilitySlots}
                hasValidationErrors={form.hasValidationErrors}
                isAvailable={isAvailable(initialForm, value)}
                key={value}
                step={step}
                updateForm={updateForm}
                validationErrors={form.validationErrors}
                values={form.values}
              />
            ))}
          </div>

          <div className="actions">
            <Button theme="primary" type="submit">
              Save
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default AvailabilityForm;
